var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.silos !== undefined
      ? _c(
          "div",
          { staticClass: "row" },
          _vm._l(_vm.silos, function(silo) {
            return _c(
              "div",
              { key: silo.siloNumber, staticClass: "col-md-4 col-6" },
              [
                _c(
                  "div",
                  {
                    staticClass: "card",
                    attrs: { title: "Details anzeigen" },
                    on: {
                      click: function($event) {
                        return _vm.navigate("siloDetail/" + silo.siloNumber)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "card-header silo-icon" }, [
                      _c(
                        "div",
                        { staticClass: "row justify-content-between" },
                        [
                          _c("div", { staticClass: "col-auto" }, [
                            _vm._v("Silo " + _vm._s(silo.siloNumber))
                          ]),
                          _vm._m(0, true)
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-12 text-left" },
                          [
                            _c("div", { staticClass: "label-big" }, [
                              _vm._v(_vm._s(silo.currentLevelInPercent) + " %")
                            ]),
                            _c("div", [_vm._v(_vm._s(silo.material))])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-12 btn-container" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "card-button d-flex align-items-end flex-row-reverse"
                              },
                              [
                                silo.hasClearanceFeature === true
                                  ? _c("clearance-button", {
                                      attrs: { silo: silo }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ]
            )
          }),
          0
        )
      : _c(
          "div",
          {
            staticClass: "d-flex align-items-center justify-content-center",
            staticStyle: { height: "80vh" }
          },
          [_c("em", { staticClass: "fas fa-spinner fa-spin fa-3x" })]
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-auto" }, [
      _c("img", {
        attrs: { src: require("../assets/silo-icon.png"), alt: "Silo Icon" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }