






























import Vue from 'vue';
import { OidcAuthService } from '@/services/OidcAuthService';
export default Vue.extend({
  name: 'Navbar',
  data: () => {
    return {
      showUsermanagement: false,
    };
  },
  created() {
    this.getShowButton();
  },
  computed: {
    username(): string {
      return (this.$user.profile && this.$user.profile.unique_name) || 'Hallo';
    },
  },
  methods: {
    logout(): void {
      OidcAuthService.signout();
    },
    async getShowButton(): Promise<void> {
      this.showUsermanagement = await OidcAuthService.userHasRole('KbsSilos_Admin');
    },
  },
});
