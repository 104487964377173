

















import Vue from 'vue';
export default Vue.extend({
  name: 'ClearanceButton',
  props: {
    silo: Object,
  },
  data: () => {
    return {
      showButton: false,
      success: undefined as boolean | undefined,
      loading: false,
    };
  },
  methods: {
    async sendClearance(siloNumber: number) {
      this.success = undefined;
      this.loading = true;
      await this.$siloService
        .sendClearance(siloNumber)
        .then(() => {
          this.success = true;
          setTimeout(() => (this.success = undefined), 3000);
        })
        .catch(() => {
          this.success = false;
          setTimeout(() => (this.success = undefined), 3000);
        });
      this.loading = false;
    },
  },
});
