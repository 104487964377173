import 'bootstrap';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import { User, UserManagerSettings } from 'oidc-client';
import { OidcAuthService } from './services/OidcAuthService';
import { SiloService } from './services/SiloService';
import { UserApiClient } from './services/UserApiClient';

Vue.config.productionTip = false;

const config: UserManagerSettings = {
  authority: process.env.VUE_APP_AUTHORITY,
  client_id: 'kbs-silos-web',
  redirect_uri: `${process.env.VUE_APP_URL_BASE}/callback`,
  response_type: 'code',
  response_mode: 'query',
  scope: 'openid profile unifiedapi kbsapi',
  post_logout_redirect_uri: `${process.env.VUE_APP_URL_BASE}`,
  automaticSilentRenew: true,
  silent_redirect_uri: `${process.env.VUE_APP_URL_BASE}/silent-renew`,
};

const successHandler = (user: User) => {
  axios.defaults.headers.Authorization = `Bearer ${user.access_token}`;
};

OidcAuthService.configure(config, router, successHandler);
OidcAuthService.ensureAuthenticated().then((user: User) => {
  Vue.prototype.$user = user;
  Vue.prototype.$siloService = new SiloService(axios, process.env.VUE_APP_REFRESH_INTERVAL);
  Vue.prototype.$usersApi = new UserApiClient(axios, process.env.VUE_APP_API_USERS);

  new Vue({
    router,
    render: (h) => h(App),
  }).$mount('#app');
});
