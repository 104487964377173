// @ts-ignore
import { UserManagerSettings, UserManager, User } from 'oidc-client';
import VueRouter from 'vue-router';

export class OidcAuthService {
  private static _config: UserManagerSettings;
  private static _successHandler: (user: User) => void;
  private static _userManager: UserManager;

  public static configure(
    config: UserManagerSettings,
    router: VueRouter | undefined,
    successHandler: (user: User) => void = () => undefined
  ): void {
    this._config = config;
    this._successHandler = successHandler;
    this._userManager = new UserManager(config);

    if (router) {
      this.addNavigationGuards(router);
    }
  }

  public static ensureAuthenticated(): Promise<User> {
    return new Promise((resolve, reject) => {
      if (this.currentPathEquals(this._config.redirect_uri)) {
        this.processSigninCallback();
        reject();
      } else if (this.currentPathEquals(this._config.silent_redirect_uri)) {
        this.processSilentRenewCallback();
        reject();
      } else {
        this._userManager
          .getUser()
          .then((user: User | null) => {
            if (user) {
              this._successHandler(user);
              resolve(user);
            } else {
              this.signinRedirect(window.location.href);
              reject();
            }
          })
          .catch(() => reject());
      }
    });
  }

  public static processSigninCallback(): Promise<void> {
    return this._userManager
      .signinRedirectCallback()
      .then((user: User) => {
        this._successHandler(user);
        window.location = user.state || '/';
      })
      .catch((error: Error) => {
        if (error.toString().includes('login_required')) {
          this.signinRedirect();
        }
      });
  }

  public static processSilentRenewCallback(): Promise<User | undefined> {
    return this._userManager.signinSilentCallback();
  }

  public static signout(): Promise<void> {
    return this._userManager.signoutRedirect();
  }

  public static async userHasRole(role: string): Promise<boolean> {
    return await this._userManager.getUser().then((user: User | null) => {
      if (user === null) {
        return false;
      }
      return OidcAuthService.hasRole(user, [role]);
    });
  }

  private static signinRedirect(url?: string): void {
    this._userManager.signinRedirect({ state: url });
  }

  private static addNavigationGuards(router: VueRouter) {
    router.beforeEach((to, from, next) => {
      if (to.meta?.requireAuthenticated || to.meta?.requireRole) {
        this._userManager.getUser().then((user: User | null) => {
          if (user) {
            if (to.meta?.requireRole) {
              if (OidcAuthService.hasRole(user, to.meta?.requireRole)) {
                next();
              } else {
                next('/accessdenied');
              }
            } else {
              next();
            }
          } else {
            OidcAuthService.ensureAuthenticated();
          }
        });
      } else {
        next();
      }
    });
  }

  private static hasRole(user: User, roles: string[]): boolean {
    roles = roles.map((r) => r.toLowerCase());
    if (user && user.profile && user.profile.role) {
      if (Array.isArray(user.profile.role)) {
        return user.profile.role.some((r: string) => roles.includes(r.toLowerCase()));
      } else {
        return roles.includes(user.profile.role.toLowerCase());
      }
    } else {
      return false;
    }
  }

  private static currentPathEquals(url: string | undefined): boolean {
    return url ? this.getPath(url).toLowerCase() === window.location.pathname.toLowerCase() : false;
  }

  private static getPath(url: string) {
    const link = document.createElement('a');
    link.href = url;
    return link.pathname[0] === '/' ? link.pathname : `/${link.pathname}`;
  }
}
