var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "topbar c-header c-header-fixed c-header-light" },
    [
      _c(
        "div",
        { staticClass: "c-header-nav" },
        [
          _c(
            "router-link",
            {
              staticClass: "c-header-toggler c-class-toggler",
              attrs: { type: "button", responsive: "true", to: "/" }
            },
            [
              _c("img", {
                staticClass: "c-header-logo",
                attrs: { alt: "logo", src: require("../assets/logo.png") }
              })
            ]
          ),
          _c("div", { staticClass: "header mr-auto" }, [
            _vm._v("Silofüllstände")
          ]),
          _c("div", { staticClass: "c-header-nav" }, [
            _c("div", { staticClass: "nav-item dropdown" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link dropdown-toggle",
                  attrs: {
                    href: "#",
                    id: "navbarDropdownMenuLink",
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false"
                  }
                },
                [_vm._v(_vm._s(_vm.username))]
              ),
              _c(
                "div",
                {
                  staticClass: "dropdown-menu dropdown-menu-right",
                  attrs: { "aria-labelledby": "navbarDropdownMenuLink" }
                },
                [
                  _vm.showUsermanagement
                    ? _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/userManagement" }
                        },
                        [_vm._v(" Benutzerverwaltung")]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "dropdown-item",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.logout()
                        }
                      }
                    },
                    [_vm._v("Abmelden")]
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }