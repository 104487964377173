
import Vue from 'vue';
import { Line } from 'vue-chartjs';
import moment from 'moment';
moment.locale('de');

export default Vue.extend({
  name: 'LineChart',
  extends: Line,
  data: () => {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 1000,
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'day',
                displayFormats: {
                  day: 'ddd DD.MM',
                },
              },
              distribution: 'linear',
              ticks: {
                source: 'auto',
              },
            },
          ],
          yAxes: [
            {
              unit: '%',
              ticks: {
                stepSize: 10,
                suggestedMin: 0,
                suggestedMax: 100,
                callback: (value: string) => {
                  return value + ' % ';
                },
              },
            },
          ],
        },
      },
    };
  },
  props: {
    Labels: Array,
    Name: String,
    chartData: Array,
    Height: Number,
    Width: Number,
  },
  mounted() {
    // @ts-ignore
    this.$refs.canvas.width = this.Width;
    // @ts-ignore
    this.$refs.canvas.height = this.Height;
    // @ts-ignore
    this.render(true);
  },
  watch: {
    chartData() {
      // @ts-ignore
      this.render(false);
      return this.chartData;
    },
  },
  methods: {
    render(animations = true) {
      if (animations === true) {
        this.options.animation.duration = 1000;
      } else {
        this.options.animation.duration = 0;
      }
      // @ts-ignore
      const gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
      gradient.addColorStop(0, 'rgba(	0, 99, 72, 0.9)');
      gradient.addColorStop(0.5, 'rgba(	0, 99, 72, 0.5)');
      gradient.addColorStop(1, 'rgba(	0, 99, 72, 0.1)');
      // @ts-ignore
      this.renderChart(
        {
          datasets: [
            {
              label: this.Name,
              backgroundColor: gradient,
              pointBorderColor: '#006348',
              pointBackgroundColor: '#006348',
              borderColor: '#006348',
              borderWidth: 1,
              data: this.chartData,
            },
          ],
        },
        this.options
      );
    },
  },
});
