import { AxiosInstance } from 'axios';
import { IUser } from '@/types/IUser';
import { CrudApi } from './CrudApi';
import { NewUser } from '@/types/NewUser';

export class UserApiClient extends CrudApi<IUser> {
  constructor(axios: AxiosInstance, baseUrl: string) {
    super(axios, `${baseUrl}/users/kbs`, (x: IUser) => x.id);
  }

  public addUser = (entity: NewUser): Promise<NewUser> =>
    this.axios.post(this.entityUrl, entity).then<NewUser>((response) => response.data);
  public lock = (entity: IUser): Promise<IUser> =>
    this.axios
      .post(`${this.entityUrl}/${this.key(entity)}/lock`, entity)
      .then<IUser>((response) => response.data);
  public unlock = (entity: IUser): Promise<IUser> =>
    this.axios
      .post(`${this.entityUrl}/${this.key(entity)}/unlock`, entity)
      .then<IUser>((response) => response.data);
}
