var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _c("div", { staticClass: "c-wrapper" }, [
      _c("div", { attrs: { id: "nav" } }, [_c("navbar")], 1),
      _vm.error !== undefined
        ? _c("div", { staticClass: "alerts" }, [_vm._m(0)])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "container" },
        [_c("transition", { attrs: { name: "fade" } }, [_c("router-view")], 1)],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "alert alert-warning alert-dismissible fade show",
        attrs: { role: "alert" }
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col col-auto" }, [
            _c("em", { staticClass: "fas fa-exclamation fa-3x" })
          ]),
          _c("div", { staticClass: "col align-self-center" }, [
            _vm._v(
              " Hoppla! Es konnten keine neuen Daten geladen werden. Es wird gleich nochmals versucht. "
            )
          ])
        ]),
        _c(
          "button",
          {
            staticClass: "close",
            attrs: {
              type: "button",
              "data-dismiss": "alert",
              "aria-label": "Close"
            }
          },
          [
            _c("em", {
              staticClass: "fas fa-times fa-2",
              attrs: { "aria-hidden": "true" }
            })
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }