





























import Vue from 'vue';
import Navbar from './components/Navbar.vue';

export default Vue.extend({
  components: {
    Navbar,
  },
  data: () => {
    return {
      error: undefined as Error | undefined,
    };
  },
  created() {
    this.$siloService.register(this.onErrors, true);
    this.$siloService.register(this.onSuccess);
  },
  beforeDestroy() {
    this.$siloService.deregister(this.onErrors, true);
    this.$siloService.deregister(this.onSuccess);
  },
  methods: {
    onErrors(error: Error) {
      this.error = error;
    },
    onSuccess() {
      this.error = undefined;
    },
  },
});
