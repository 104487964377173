import { ISilo } from '@/types/ISilo';
import { ISilolevelHistoryItem } from '@/types/ISilolevelHistoryItem';
import { AxiosStatic } from 'axios';
import { RemoteData } from 'rey-frontend-fp';
import moment from 'moment';

export class SiloService {
  private axios: AxiosStatic;
  private handlers: { (data?: ISilo[]): void }[] = [];
  private errorHandlers: { (error?: Error): void }[] = [];

  constructor(axios: AxiosStatic, interval: number) {
    this.axios = axios;
    setInterval(() => this.UpdateSilos(), interval);
  }

  public register(
    handler: { (data?: ISilo[]): void } | { (error?: Error): void },
    error = false
  ): void {
    if (error === true) {
      this.errorHandlers.push(handler as { (error?: Error): void });
    } else {
      this.handlers.push(handler as { (data?: ISilo[]): void });
    }
  }

  public deregister(
    handler: { (data?: ISilo[]): void } | { (error?: Error): void },
    error = false
  ): void {
    if (error === true) {
      this.errorHandlers = this.errorHandlers.filter((h) => h !== handler);
    } else {
      this.handlers = this.handlers.filter((h) => h !== handler);
    }
  }

  public async UpdateSilos(): Promise<void> {
    await this.axios
      .get<ISilo[]>(`${process.env.VUE_APP_API_URL}/silo/overview`)
      .then((data) => {
        if (data) {
          this.handlers.slice(0).forEach((h) => h(data.data));
        }
      })
      .catch((err) => this.errorHandlers.slice(0).forEach((h) => h(err)));
  }

  public async sendClearance(siloNumber: number): Promise<void> {
    await this.axios.post(
      `${process.env.VUE_APP_API_URL}/silo/sendclearancesign?siloNumber=${siloNumber}`
    );
    this.UpdateSilos();
  }

  public async getChartData(
    siloNumber: number
  ): Promise<RemoteData<ISilolevelHistoryItem[], Error | string>> {
    const end = moment(new Date()).format('YYYY-MM-DD[T]hh:mm:ss');
    const start = moment(new Date()).subtract(1, 'weeks').format('YYYY-MM-DD[T]hh:mm:ss');
    return await this.axios
      .get<ISilolevelHistoryItem[]>(
        `${process.env.VUE_APP_API_URL}/silo/silodetails?siloNumber=${siloNumber}&start=${start}&end=${end}`
      )
      .then((res) => RemoteData.success<ISilolevelHistoryItem[], Error | string>(res.data))
      .catch((err) => RemoteData.failure<ISilolevelHistoryItem[], Error | string>(err));
  }
}
