import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import SiloOverview from '../views/SiloOverview.vue';
import SiloDetail from '../views/SiloDetail.vue';
import UserManagement from '../views/UserManagement.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'SiloOverview',
    meta: {
      requireAuthenticated: true,
      requireRole: ['KbsSilos_User', 'KbsSilos_Admin'],
    },
    component: SiloOverview,
  },
  {
    path: '/siloDetail/:siloNumber',
    name: 'SiloDetail',
    meta: {
      requireAuthenticated: true,
      requireRole: ['KbsSilos_User', 'KbsSilos_Admin'],
    },
    component: SiloDetail,
  },
  {
    path: '/userManagement',
    name: 'UserManagement',
    meta: {
      requireAuthenticated: true,
      requireRole: ['KbsSilos_Admin'],
    },
    component: UserManagement,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
