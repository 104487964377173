

























































import { ISilo } from '@/types/ISilo';
import { ISilolevelHistoryItem } from '@/types/ISilolevelHistoryItem';
import Vue from 'vue';
import LineChart from '@/components/LineChart.vue';
import ClearanceButton from '@/components/ClearanceButton.vue';
import moment from 'moment';

export default Vue.extend({
  name: 'SiloDetail',
  components: {
    LineChart,
    ClearanceButton,
  },
  data: () => {
    return {
      siloNumber: undefined as number | undefined,
      silo: undefined as ISilo | undefined,
      chartData: [] as ISilolevelHistoryItem[],
    };
  },
  created() {
    this.siloNumber = Number(this.$route.params.siloNumber);
    this.$siloService.register(this.onSiloDetailsLoaded);
    this.$siloService.UpdateSilos();
  },
  beforeDestroy() {
    this.$siloService.deregister(this.onSiloDetailsLoaded);
  },
  methods: {
    onSiloDetailsLoaded(silos: ISilo[]) {
      this.silo = silos.filter((s) => s.siloNumber === this.siloNumber)[0];
      this.$siloService.getChartData(this.silo.siloNumber).then(this.fillChartData);
    },
    sendClearance() {
      this.$siloService.sendClearance(this.siloNumber);
    },
    fillChartData(rd: unknown) {
      // @ts-ignore
      this.chartData = rd.getData().map((level) => this.getXYType(level.timestamp, level.value));
    },
    getXYType(x: Date, y: number): { x: moment.Moment; y: number } {
      return { x: moment(x), y: y };
    },
  },
});
