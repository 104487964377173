var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.silo.clearanceBitSet === true
      ? _c(
          "button",
          { staticClass: "btn btn-primary mt-2", attrs: { disabled: "" } },
          [
            _vm.loading === true
              ? _c("em", { staticClass: "fas fa-spinner fa-spin" })
              : _vm.success === true
              ? _c("em", { staticClass: "fas fa-check bounce" })
              : _vm.success === false
              ? _c("em", { staticClass: "fas fa-times bounce" })
              : _c("span", [_vm._v("Freigegeben")])
          ]
        )
      : _c(
          "button",
          {
            staticClass: "btn btn-secondary mt-2",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.sendClearance(_vm.silo.siloNumber)
              }
            }
          },
          [
            _vm.loading === true
              ? _c("em", { staticClass: "fas fa-spinner fa-spin" })
              : _vm.success === true
              ? _c("em", { staticClass: "fas fa-check bounce" })
              : _vm.success === false
              ? _c("em", { staticClass: "fas fa-times bounce" })
              : _c("span", [_vm._v("Freigeben")])
          ]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }