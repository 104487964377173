
















































































































































import Vue from 'vue';
import {
  Config,
  Column,
  Sorting,
  PagingOptions,
  SmartTableAddDialog,
  SmartTable,
} from 'rey-vue-smarttable';
// @ts-ignore
import { Alert } from 'rey-vue-common';
import { IUser } from '@/types/IUser';
import { NewUser } from '@/types/NewUser';

export default Vue.extend({
  components: {
    SmartTableAddDialog,
    SmartTable,
    Alert,
  },
  data() {
    return {
      model: new NewUser(),
      config: new Config(
        (x) => x.email,
        [
          new Column({
            title: 'Name',
            fieldName: 'lastName',
            editable: () => true,
          }),
          new Column({
            title: 'Vorname',
            fieldName: 'givenName',
            editable: () => true,
          }),
          new Column({
            title: 'Firma',
            fieldName: 'company',
          }),
          new Column({
            title: 'Email',
            fieldName: 'email',
          }),
          new Column({
            title: 'PLZ',
            fieldName: 'postalCode',
          }),
          new Column({
            title: 'Ort',
            fieldName: 'city',
          }),
          new Column({
            title: 'Aktiv',
            fieldName: 'isActive',
            fieldType: 'boolean',
          }),
        ],
        new Sorting('company'),
        new PagingOptions(),
        undefined,
        this.$usersApi
      ),
      selected: undefined as IUser | undefined,
      apiError: undefined as Error | undefined,
      apiErrorAdd: undefined as Error | undefined,
      apiSuccess: false,
      isWorking: false,
    };
  },
  computed: {
    resetPasswordLink(): string {
      return `${process.env.VUE_APP_AUTHORITY}/account/resetpassword?email=${this.selected?.email}`;
    },
  },
  methods: {
    async lockUser() {
      if (!this.selected) {
        return;
      }
      try {
        this.apiError = undefined;
        this.isWorking = true;

        await this.$usersApi.lock(this.selected);
        // @ts-ignore
        this.$refs.smarttable.$_SmartTable_loadElements();
        this.apiSuccess = true;
        this.selected = undefined;
      } catch (error: unknown) {
        this.apiError = error as Error;
      } finally {
        this.isWorking = false;
      }
    },
    async unlockUser() {
      if (!this.selected) {
        return;
      }
      try {
        this.apiError = undefined;
        this.isWorking = true;

        await this.$usersApi.unlock(this.selected);
        // @ts-ignore
        this.$refs.smarttable.$_SmartTable_loadElements();
        this.apiSuccess = true;
        this.selected = undefined;
      } catch (error: unknown) {
        this.apiError = error as Error | undefined;
      } finally {
        this.isWorking = false;
      }
    },
    async add() {
      await this.$usersApi
        .addUser(this.model)
        .then(() => {
          // @ts-ignore
          this.$refs.smartTableAddDialog.hide();
          // @ts-ignore
          this.$refs.smarttable.$_SmartTable_loadElements();
        })
        .catch((err: Error) => (this.apiErrorAdd = err));
    },
    showDialog() {
      this.model = new NewUser();
      this.apiErrorAdd = undefined;
      // @ts-ignore
      this.$refs.smartTableAddDialog.show();
    },
  },
});
