// tslint:disable: member-ordering
import { IActions, State, Config } from 'rey-vue-smarttable';
import { AxiosInstance } from 'axios';
import Qs from 'qs';

export class CrudApi<TEntity> implements IActions {
  protected axios: AxiosInstance;
  protected key: (entity: TEntity) => string | number;
  protected entityUrl: string;

  constructor(axios: AxiosInstance, entityUrl: string, key: (x: TEntity) => string | number) {
    this.axios = axios;
    this.entityUrl = entityUrl;
    this.key = key;
  }

  public add = (entity: TEntity): Promise<TEntity> =>
    this.axios.post(this.entityUrl, entity).then<TEntity>((response) => response.data);

  public update = (entity: TEntity): Promise<TEntity> =>
    this.axios
      .put(`${this.entityUrl}/${this.key(entity)}`, entity)
      .then<TEntity>((response) => response.data);

  public delete = (entity: TEntity): Promise<TEntity> =>
    this.axios
      .delete(`${this.entityUrl}/${this.key(entity)}`, { data: entity })
      .then((response) => response.data);

  public stateChanged = (
    skip: number,
    take: number,
    state: State,
    config: Config
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    const allFilters = config.permanentFilters.concat(state.filters);
    const contains = allFilters
      .filter((f) => f.filterType === 'contains')
      .map((filter) => `${filter.fieldName}=${filter.filterQuery.join('||')}`);
    const equals = allFilters
      .filter((f) => f.filterType === 'equals')
      .map((filter) =>
        filter.fieldType === 'boolean'
          ? `${filter.fieldName}=${filter.filterQuery.join('||').indexOf('true') >= 0 ? '1' : '0'}`
          : `${filter.fieldName}=${filter.filterQuery.join('||')}`
      );
    return this.axios
      .get(this.entityUrl, {
        params: {
          skip,
          take,
          orderBy: state.sorting.sortBy,
          descending: state.sorting.sortMode === 'descending',
          contains,
          equals,
        },
        paramsSerializer(params) {
          return Qs.stringify(params, { arrayFormat: 'repeat' });
        },
      })
      .then((response) => response.data);
  };

  public load = this.stateChanged;
}
