var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("div", { staticClass: "row justify-content-between" }, [
            _c("div", { staticClass: "col-auto" }, [
              _vm._v("Silo " + _vm._s(_vm.siloNumber))
            ]),
            _c(
              "div",
              { staticClass: "col-auto" },
              [
                _c("router-link", { attrs: { to: "/" } }, [
                  _c("span", { staticClass: "enlarge fas fa-times fa-lg" })
                ])
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "card-body" }, [
          _vm.chartData === [] || _vm.silo === undefined
            ? _c("em", { staticClass: "fas fa-spinner fa-spin fa-3x" })
            : _c("div", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6 col-12 text-left" }, [
                    _c("div", { staticClass: "label-big" }, [
                      _vm.silo !== undefined
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                "Füllstand: " +
                                  _vm.silo.currentLevelInPercent +
                                  " %"
                              )
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("div", [
                      _vm.silo !== undefined
                        ? _c("span", [
                            _vm._v(_vm._s("Material: " + _vm.silo.material))
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "col-md-6 col-12" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-button d-flex align-items-end flex-row-reverse"
                      },
                      [
                        _vm.silo !== undefined &&
                        _vm.silo.hasClearanceFeature === true
                          ? _c("clearance-button", {
                              attrs: { silo: _vm.silo }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ]),
                _c("hr"),
                _c("div", { staticClass: "row chart-row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c("line-chart", {
                        attrs: {
                          Height: 600,
                          Width: 600,
                          Name: "Füllstände",
                          chartData: _vm.chartData
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }