export class NewUser {
  public id = '';
  public lastName = '';
  public givenName = '';
  public company = '';
  public postalCode = '';
  public city = '';
  public customerId = '';
  public email = '';
  public password = '';
}
