










































import { ISilo } from '@/types/ISilo';
import Vue from 'vue';
import ClearanceButton from '@/components/ClearanceButton.vue';

export default Vue.extend({
  name: 'SiloOverview',
  components: {
    ClearanceButton,
  },
  data: () => {
    return {
      silos: undefined as ISilo[] | undefined,
      siloDetail: 0 as number,
    };
  },
  created() {
    this.$siloService.register(this.onSilosLoaded);
    this.$siloService.UpdateSilos();
  },
  beforeDestroy() {
    this.$siloService.deregister(this.onSilosLoaded);
  },
  methods: {
    onSilosLoaded(silos: ISilo[]): void {
      this.silos = silos;
    },

    navigate(url: string) {
      this.$router.push(url);
    },
  },
});
